import React from 'react';

import { AnalyticsEventMap } from '~/constants/AnalyticsEventMap';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { useIdentifyPosthogUser } from '~/hooks/tracking/useIdentifyPosthogUser';
import type { TrackingMetaData } from '~/hooks/tracking/useTracking';
import { useGetUserID } from '~/hooks/useGetUserID';
import { useGoogleTagManager } from '~/hooks/useGoogleTagManager';

import { hasEventHandler, injectPosthogEvent, TrackEventOptions } from './helpers/trackingInjection';
import { withExternalCallbackInjection } from './helpers/withExternalCallbackInjection';

/** interface for tracking an event on a component */
export type TrackEventProps = TrackEventOptions<AnalyticsEventMap, keyof AnalyticsEventMap>;

/** Track any event by wrapping what you want to have events be tracked when clicked or on keydown */
export function TrackEvent<T extends AnalyticsEventMap, K extends keyof AnalyticsEventMap>({
    eventName,
    metaData,
    children
}: React.PropsWithChildren<{
    eventName: K;
    metaData?: TrackingMetaData<T, K>;
}>) {
    void PosthogHelper.init();
    const userID = useGetUserID();
    useIdentifyPosthogUser();
    const { googleTagManager } = useGoogleTagManager();
    let trackedElement;
    if (React.isValidElement(children)) {
        const { onClick, onKeyPress, onTouchEnd, onSubmit } = children.props;
        const {
            onClick: hasOnClick,
            onKeyPress: hasOnKeyDown,
            onTouchEnd: hasOnTouchEnd,
            onSubmit: hasOnSubmit
        } = hasEventHandler({ onClick, onKeyPress, onTouchEnd, onSubmit });

        const injectedEventHandlers = injectPosthogEvent(
            { eventName, metaData: metaData ?? ((() => {}) as any), googleTagManager, userID },
            { onClick, onKeyPress, onTouchEnd }
        );

        if (hasOnClick || hasOnKeyDown || hasOnTouchEnd || hasOnSubmit) {
            trackedElement = React.cloneElement(children, { ...children.props, ...injectedEventHandlers });
        }
        trackedElement = withExternalCallbackInjection(injectedEventHandlers)(children, children.props);
    }

    return <>{trackedElement}</>;
}
