import React from 'react';
import Image from 'next/legacy/image';

import arrowBottom from '~/assets/img/liquidate/arrow-bottom.svg';

interface TimelineContentProps {
    title?: string;
    description?: string;
    duration?: string;
    badge?: string;
}

export const TimelineContent: React.FC<TimelineContentProps> = ({ title, description, duration, badge }) => {
    return (
        <div className={'relative mb-7'}>
            <div className={'absolute -left-9'}>
                <Image src={arrowBottom} />
            </div>
            <div className={'flex items-center mb-1'}>
                <h4 className={'font-roslindale font-bold text-[20px] text-[#242e35]'}>
                    {title}{' '}
                    {badge && (
                        <span
                            className={
                                'bg-yellow-600 text-12 font-mono font-normal uppercase rounded-full xs:mx-1 mx-3 px-2 py-1'
                            }
                        >
                            {badge}
                        </span>
                    )}
                </h4>
                {duration && (
                    <span className={'font-vinovestmedium font-medium text-[10px] text-[#A8ABAD] ml-2.5'}>
                        {duration}
                    </span>
                )}
            </div>
            <p className={'font-vinovest font-medium text-[12px] text-black'}>{description}</p>
        </div>
    );
};
