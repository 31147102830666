import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

interface ContainerProps extends ClassNamesProps {
    maxWidth?: string;
    padding?: string;
}

export const Container: React.FC<ContainerProps> = ({ maxWidth, padding, classnames, children }) => (
    <div className={classNames(`mx-auto ${padding || 'px-4 lg:px-0'}`, `${maxWidth || 'container'}`, classnames)}>
        {children}
    </div>
);
