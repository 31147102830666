import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';
import type { FontWeight, TextColor } from '~/vinovest-design-system/types/tailwind';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement>, ClassNamesProps {
    level: Exclude<keyof typeof HeadingType, 'display' | 'custom'>;
    type?: keyof typeof HeadingType;
    color?: TextColor;
    fontWeight?: FontWeight;
    tag?: string;
}

export const base = 'font-roslindale capitalize';

export const HeadingType = {
    display: 'text-64 sm:text-96',
    h1: 'text-32 sm:text-64',
    h2: 'xs:text-24 md:text-36 text-45 ',
    h3: 'text-30 sm:text-36',
    h4: 'text-24 sm:text-30',
    h5: 'text-20 sm:text-24',
    h6: 'text-18 sm:text-20',
    custom: ''
};

export const Heading: React.FC<HeadingProps> = ({ ...props }) => {
    const { tag, level, type, color, fontWeight, classnames, ...rest } = props;

    const HeadingTag = `${tag || level}`;

    return (
        <HeadingTag
            className={classNames(
                base,
                HeadingType[type || level],
                [color || 'text-neutrals-900'],
                [fontWeight || 'font-[400]'],
                [classnames || '']
            )}
            {...rest}
        />
    );
};
