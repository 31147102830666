import { Fragment, useContext, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { ModalRouterContext } from '~/context/ModalRouterContext';
import { ClassNamesProps } from '~/models/ClassNamesProps';
import { IconClose } from '~/vinovest-design-system/components/Icons';

export interface ModalProps extends ClassNamesProps {
    children: React.ReactNode;
    open: boolean;
    setOpen(value: boolean): void;
    title?: string;
    fullPage?: boolean;
    routerBack?: boolean;
    position?: 'center' | 'bottom';
    isCloseBtn?: boolean;
}

/** Popup that is triggered by an external button, and can hold any content. */
export const Modal = ({
    children,
    open,
    classnames,
    setOpen,
    fullPage,
    routerBack,
    title,
    position = 'center',
    isCloseBtn = true
}: ModalProps) => {
    const initFocus = useRef(null);
    const router = useRouter();
    const modalRouterContext = useContext(ModalRouterContext);

    useEffect(() => {
        router.beforePopState((state) => {
            state.options.scroll = false;
            return true;
        });
    }, []);

    const handleModalClose = () => {
        if (routerBack) {
            modalRouterContext.handleModalData({});

            router.back();
        } else {
            setOpen(false);
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as={'div'}
                className={'z-50 inset-0 overflow-y-auto fixed bottom-0 h-full'}
                onClose={handleModalClose}
                initialFocus={initFocus}
            >
                <div
                    ref={initFocus}
                    className={`flex items-center justify-center min-h-screen text-center sm:block   ${
                        fullPage ? 'fixed w-full h-full mx-0' : 'p-0 md:pt-4 md:px-4 md:pb-20'
                    }`}
                >
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className={'hidden sm:inline-block sm:align-middle sm:h-screen'} aria-hidden={'true'}>
                        &#8203;
                    </span>
                    {fullPage && (
                        <div>
                            <div className={'block fixed top-0 right-0 pt-4 pr-4'}>
                                <button
                                    type={'button'}
                                    className={
                                        ' bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-3 print:hidden '
                                    }
                                    onClick={handleModalClose}
                                >
                                    <span className={'sr-only'}>Close</span>
                                    <IconClose height={'12px'} width={'12px'} stroke={'#242E35'} />
                                </button>
                            </div>
                            {title && <Dialog.Title className={'pt-2'}>{title}</Dialog.Title>}
                        </div>
                    )}

                    <Transition.Child
                        as={Fragment}
                        enter={'ease-out duration-300'}
                        enterFrom={'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}
                        enterTo={'opacity-100 translate-y-0 sm:scale-100'}
                        leave={'ease-in duration-200'}
                        leaveFrom={'opacity-100 translate-y-0 sm:scale-100'}
                        leaveTo={'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}
                    >
                        <div
                            className={classNames(
                                position === 'bottom' ? baseBottom : base,
                                print,
                                fullPage ? 'mt-[35px] mb-0 animate-slideUp h-full overflow-y-auto' : 'sm:my-8',
                                [classnames]
                            )}
                        >
                            {!fullPage && isCloseBtn && (
                                <div>
                                    <div className={'block absolute top-0 right-0 pt-4 pr-4'}>
                                        <button
                                            type={'button'}
                                            className={
                                                ' bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-3 print:hidden '
                                            }
                                            onClick={handleModalClose}
                                        >
                                            <span className={'sr-only'}>Close</span>
                                            <IconClose height={'12px'} width={'12px'} stroke={'#242E35'} />
                                        </button>
                                    </div>
                                    {title && <Dialog.Title className={'pt-2'}>{title}</Dialog.Title>}
                                </div>
                            )}
                            <div className={`${isCloseBtn ? 'mt-6' : 'mt-2'} lg:mt-0'`}>{children}</div>
                        </div>
                    </Transition.Child>
                </div>
                <Transition.Child
                    as={Fragment}
                    enter={'ease-out duration-300'}
                    enterFrom={'opacity-0'}
                    enterTo={'opacity-100'}
                    leave={'ease-in duration-200'}
                    leaveFrom={'opacity-100'}
                    leaveTo={'opacity-0'}
                >
                    <Dialog.Overlay
                        className={
                            'fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity print:bg-transparent z-[-1]'
                        }
                    />
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
};

const base =
    'relative inline-block align-bottom bg-white rounded-lg p-4 text-left shadow-xl transform transition-all sm:align-middle w-full sm:p-6';
const baseBottom =
    'fixed bottom-0 w-full inline-block align-bottom bg-white rounded-t-lg p-4 text-left shadow-xl transform transition-all sm:align-middle sm:w-full sm:p-6';
const print =
    'print:absolute print:top-0 print:bottom-0 print:left-0 print:right-0 print:margin-0 print:max-w-none print:rounded-none print:h-srceen print:shadow-none';
