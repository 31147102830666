/** Joins args into a className string
 * @param args list of objects, string, number, or arrays to reduce
 * Simple Example: aggregateCssClasses('className1')
 * Extreme Example: aggregateCssClasses(isClassName1True && 'className1', ['className2', 'className3'], 'className4', `classNameId_${5}`)
 * */
export function aggregateCssClasses(...args: (boolean | Object | string | number | undefined)[]): string {
    const classes = [] as string[];
    const hasOwn = {}.hasOwnProperty;

    args.filter(Boolean).forEach((arg: any) => {
        const argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            classes.push(arg);
        } else if (Array.isArray(arg) && arg.length > 0) {
            const inner = aggregateCssClasses(...(arg as any));
            if (inner) {
                classes.push(inner);
            }
        } else if (argType === 'object') {
            for (const key in arg) {
                if (hasOwn.call(arg, key) && arg[key]) {
                    classes.push(key);
                }
            }
        }
    });

    return classes.join(' ');
}
