import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

export interface ListItemProps extends ClassNamesProps {
    variant?: keyof typeof ListVariant;
}

const ListVariant = {
    vertical: 'my-2',
    horizontal: 'mx-2'
};

export const ListItem: React.FC<ListItemProps> = React.forwardRef<HTMLLinkElement, ListItemProps>(
    ({ classnames, children, ...props }, _) => {
        const { variant } = props;

        return (
            <li className={classNames(base, ListVariant[variant || 'vertical'], classnames)} {...props}>
                {children}
            </li>
        );
    }
);
const base = 'text-16';
