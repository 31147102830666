import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

interface BoxProps extends ClassNamesProps {
    id?: string;
}

export const Box: React.FC<PropsWithChildren<BoxProps>> = ({
    classnames,
    id,
    children
}: PropsWithChildren<BoxProps>) => (
    <div id={id} className={classNames(classnames)}>
        {children}
    </div>
);
