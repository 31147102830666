import React, { PropsWithChildren, ReactNode } from 'react';

export interface PlaceholderComponentProps {
    placeholder?: JSX.Element | null;
    // Simple Case
    renderCondition?: boolean;
    // Or functional render child with correct type checking from placeholder
    children?: ReactNode | ReactNode[];
}

export function Placeholder({ placeholder, renderCondition, children }: PropsWithChildren<PlaceholderComponentProps>) {
    if (!renderCondition) {
        if (placeholder) return placeholder;
        return null;
    }
    return <>{children || null}</>;
}
