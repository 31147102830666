import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';
import type { FontWeight, TextColor } from '~/vinovest-design-system/types/tailwind';

export interface TextProps extends HTMLAttributes<HTMLHeadingElement>, ClassNamesProps {
    as?: keyof typeof TextAs;
    variant?: keyof typeof TextVariant;
    color?: TextColor;
    fontWeight?: FontWeight;
    uppercase?: boolean;
    lowercase?: boolean;
    capitalize?: boolean;
}

const TextAs = {
    p: '',
    span: ''
};

const TextVariant = {
    body: 'font-vinovestmedium',
    label: 'font-mono',
    roslindale: 'font-roslindale'
};

export const Text: React.FC<TextProps> = ({ classnames, as, ...props }) => {
    // eslint-disable-next-line prefer-const
    let { variant, color, fontWeight, uppercase, lowercase, capitalize, ...rest } = props;

    const TextTag = `${as || 'p'}`;

    // not everything uses the props and sadly sometimes tailwind precedents change
    if (classnames?.includes('roslindale')) {
        variant = 'roslindale';
    } else if (classnames?.includes('font-mono')) {
        variant = 'label';
    }

    if (classnames?.includes('font-bold')) {
        fontWeight = 'font-bold';
    }

    return (
        <TextTag
            className={classNames(
                TextAs[as || 'p'],
                TextVariant[variant || 'body'],
                [color || 'text-neutrals-900'],
                [fontWeight || 'font-normal'],
                [uppercase ? 'uppercase' : ''],
                [lowercase ? 'lowercase' : ''],
                [capitalize ? 'capitalize' : ''],
                classnames
            )}
            {...rest}
        />
    );
};
