import React from 'react';
import styled from 'styled-components';

interface TimelineItemProps {
    children?: React.ReactNode;
    step?: number;
    title?: string;
}

export const TimelineItem: React.FC<TimelineItemProps> = ({ children, step, title }) => {
    return (
        <Container>
            <div
                className={
                    'absolute w-[30px] h-[30px] bg-[#242E35] rounded-full font-vinovestmedium text-white font-medium text-sm flex items-center justify-center -ml-3.5 z-10'
                }
            >
                {step}
            </div>
            <div className={'pl-[30px]'}>
                <div
                    className={
                        'inline-block py-[7px] px-5 font-vinovestmedium font-medium text-[12px] text-white bg-[#242E35] rounded-2xl mb-8 uppercase'
                    }
                >
                    {title}
                </div>
                {children}
            </div>
        </Container>
    );
};

const Container = styled.div<any>`
    position: relative;
    display: flex;
    &:not(:last-child):after {
        content: '';
        position: absolute;
        width: 2px;
        background-color: #242e35;
        top: 0;
        bottom: 0;
    }
`;
