import React from 'react';

export interface HeaderProps {
    left?: React.ReactNode;
    middle?: React.ReactNode;
    right?: React.ReactNode;
    middleClass?: React.HTMLProps<HTMLDivElement>['className'];
    containerClasses?: React.HTMLProps<HTMLDivElement>['className'];
    leftClass?: React.HTMLProps<HTMLDivElement>['className'];
    style?: React.CSSProperties;
    leftInnerClass?: React.HTMLProps<HTMLDivElement>['className'];
    rightClass?: React.HTMLProps<HTMLDivElement>['className'];
    rightInnerClass?: React.HTMLProps<HTMLDivElement>['className'];
}
export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
    left,
    right,
    middle,
    middleClass,
    containerClasses,
    leftInnerClass,
    rightClass,
    rightInnerClass,
    leftClass,
    style
}) => {
    return (
        <div
            style={style}
            className={`grid grid-cols-3 grid-rows-1 h-[60px] md:max-h-[90px] md:h-[90px] !border-0 ${
                containerClasses ?? ''
            }`}
        >
            <div className={leftClass || 'flex flex-col justify-center'}>
                <div className={leftInnerClass || 'flex justify-start'}>{left}</div>
            </div>
            <div className={middleClass || 'flex flex-col justify-center'}>{middle}</div>
            <div className={rightClass || 'flex flex-col justify-center'}>
                <div className={rightInnerClass || 'flex justify-end'}>{right}</div>
            </div>
        </div>
    );
};
