import React from 'react';

/** Handle all kinds of events */
interface WithExternalCallbackInjectionEventHandlers {
    [key: string]: (e: KeyboardEvent | MouseEvent | TouchEvent) => void;
}

/** Used when there are no callbacks to inject into */
export const withExternalCallbackInjection =
    (eventHandlers: WithExternalCallbackInjectionEventHandlers) =>
    (children: JSX.Element, ...props: any[]) => {
        return (
            <span {...eventHandlers}>
                {(React.isValidElement(children) && React.cloneElement(children, { ...props[0], ...eventHandlers })) ||
                    children}
            </span>
        );
    };
