import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

export const Grid: React.FC<ClassNamesProps> = ({ classnames, children }) => (
    <div className={classNames(base, classnames)}>{children}</div>
);

export const base = 'grid';
