import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

export const Flex: React.FC<ClassNamesProps> = ({ classnames, children, ...props }) => (
    <div className={classNames(base, classnames)} {...props}>
        {children}
    </div>
);
export const base = 'flex';
