import React from 'react';

import { Flex } from '~/vinovest-design-system/components';

export interface ToggleGroupProps extends React.HTMLProps<HTMLDivElement> {
    children?: React.ReactNode;
    'aria-label'?: string;
    classnames?: string;
}

export const ToggleGroup: React.FunctionComponent<ToggleGroupProps> = ({
    children,
    'aria-label': ariaLabel,
    classnames
}: ToggleGroupProps) => {
    const toggleGroupItemList = [] as any[];
    React.Children.forEach(children, (child) => {
        toggleGroupItemList.push(child);
    });

    return (
        <Flex classnames={classnames} aria-label={ariaLabel}>
            {toggleGroupItemList}
        </Flex>
    );
};
ToggleGroup.displayName = 'ToggleGroup';
