import * as React from 'react';
import classNames from 'classnames';

import { Box } from '~/vinovest-design-system/components';
import { aggregateCssClasses } from '~/vinovest-design-system/utils';

export interface ToggleGroupItemProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
    variant?: keyof typeof ButtonType;
    text?: React.ReactNode;
    disabled?: boolean;
    isDisabled?: boolean;
    isSelected?: boolean;
    'aria-label'?: string;
    buttonId?: string;
    onChange?: (selected: boolean, event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent) => void;
}

const base = 'font-vinovestmedium uppercase text-11 lg:text-14 rounded-md leading-6 py-2 px-4';

const ButtonType = {
    custom: '',
    primary: 'bg-platinum-800 text-white',
    secondary: 'bg-neutrals-200 text-platinum-800 text-platinum-500',
    tertiary: 'text-platinum-800 text-platinum-500'
};

const ButtonDisabled = {
    custom: 'pointer-events-none',
    primary: 'pointer-events-none bg-gray-200',
    secondary: 'pointer-events-none text-gray-300 border-gray-300',
    tertiary: 'pointer-events-none bg-gray-200 text-neutrals-300'
};

export const ToggleGroupItem: React.FunctionComponent<ToggleGroupItemProps> = ({
    text,
    variant,
    isDisabled = false,
    isSelected,
    'aria-label': ariaLabel = '',
    onChange = () => {},
    buttonId = ''
}: ToggleGroupItemProps) => {
    const handleChange = (event: any): void => {
        onChange(!isSelected, event);
    };

    return (
        <Box classnames={'mr-2'}>
            <button
                type={'button'}
                className={classNames(
                    base,
                    ButtonType[variant || 'primary'],
                    {
                        [ButtonDisabled[variant || 'primary']]: isDisabled
                    },
                    aggregateCssClasses(isSelected && 'selected')
                )}
                aria-pressed={isSelected}
                onClick={handleChange}
                {...(ariaLabel && { 'aria-label': ariaLabel })}
                {...(isDisabled && { disabled: true })}
                {...(buttonId && { id: buttonId })}
            >
                {text}
            </button>
        </Box>
    );
};
ToggleGroupItem.displayName = 'ToggleGroupItem';
