import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

export interface LoadingSkeletonProps extends ClassNamesProps {
    height: string;
    width: string;
    background?: string;
}

/** Animated loading state used in the place of individual page elements. */
export const LoadingSkeleton = ({
    height,
    width,
    background = 'bg-neutrals-200',
    classnames
}: LoadingSkeletonProps) => {
    return <div className={classNames('animate-pulse', [classnames, background])} style={{ height, width }} />;
};
