import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

interface ListProps extends ClassNamesProps {
    as?: keyof typeof ListType;
    children?: React.ReactNode;
}

const ListType = {
    ul: '',
    ol: ''
};

export const List: React.FC<ListProps> = ({ classnames, as, children, ...props }) => {
    const ListTag: any = `${as || 'ul'}`;
    return (
        <ListTag className={classNames(ListTag === 'ol' ? 'auto' : 'list-none', classnames)} {...props}>
            {children}
        </ListTag>
    );
};
