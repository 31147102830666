import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

/**
 * Breadcrumbs: Styled breadcrumb wrapper
 * @param {children, classnames} - takes in Tailwind classes and React children
 * @returns - Returns a styled HTML wrapper
 */
export const Breadcrumbs: React.FC<ClassNamesProps> = ({ children, classnames }) => {
    return (
        <nav className={classNames(base)}>
            <ul className={classNames([classnames])}>{children}</ul>
        </nav>
    );
};

export const base = 'container m-auto text-14';
