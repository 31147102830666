import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';
import type { FontFamily, FontSize } from '~/vinovest-design-system/types/tailwind';

interface BadgeProps extends ClassNamesProps {
    variant?: keyof typeof BadgeType;
    fontFamily?: FontFamily | string;
    FontSize?: FontSize;
}

export const Badge: React.FC<BadgeProps> = ({ children, ...props }) => {
    const { classnames, fontFamily, FontSize, variant } = props;
    return (
        <span
            className={classNames(
                base,
                BadgeType[variant || 'primary'],
                [fontFamily || 'font-mono'],
                [FontSize || 'text-11'],
                classnames
            )}
        >
            {children}
        </span>
    );
};

export const base = 'uppercase rounded-full py-2 px-3 inline-block';

export const BadgeType = {
    primary: 'bg-neutrals-800 text-white',
    success: 'bg-green-600 text-white',
    error: 'bg-red-600 text-white',
    warning: 'bg-yellow-600 text-platinum-800',
    info: 'bg-white text-platinum-800 border border-platinum-800',
    turtleGreen: 'bg-turtleGreen-200 text-neutrals-900',
    custom: ''
};
